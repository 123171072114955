import { Card, Checkbox } from "antd";
import React, { memo } from "react";
import Draggable from "react-draggable";

function FormViewCheckAllBoxes({ singleField, trackPositions, onCheckAllBoxesChanged, reviewer, disabled }) {
    const isChecked = singleField.checked || singleField.value;

    const handleChange = (e) => {
        console.log('Check All Box clicked with value:', e.target.checked);
        console.log('Current field state before update:', singleField);
        onCheckAllBoxesChanged(e, singleField);
    };

    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            disabled={disabled}
        >
            <div className="box-draggable">
                <Card>
                    <Checkbox
                        onChange={handleChange}
                        checked={isChecked}
                        disabled={reviewer || disabled}
                        style={{ 
                            width: singleField.width, 
                            height: singleField.height 
                        }}
                        className={`ant-select-status-${singleField.validationStatus}`}
                    >
                    </Checkbox>
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewCheckAllBoxes);
