import React, { memo } from "react";
import { FIELD_TYPES } from "util/util.constant";
import FormViewCompany from "./FormViewFields/FormViewCompany/FormViewCompany";
import FormViewDatePicker from "./FormViewFields/FormViewDatePicker/FormViewDatePicker";
import FormViewDropdown from "./FormViewFields/FormViewDropdown/FormViewDropdown";
import FormViewEmail from "./FormViewFields/FormViewEmail/FormViewEmail";
import FormViewInput from "./FormViewFields/FormViewInput/FormViewInput";
import FormViewInputTextarea from "./FormViewFields/FormViewInputTextarea/FormViewInputTextarea";
import FormViewName from "./FormViewFields/FormViewName/FormViewName";
import FormViewTimePicker from "./FormViewFields/FormViewTimePicker/FormViewTimePicker";
import FormViewCheckAllBoxes from "./FormViewFields/FormViewCheckAllBoxes/FormViewCheckAllBoxes";

function PrintFieldsInBuilderView({
    singleField,
    trackPositions,
    onTextChangeHandler,
    onDatePickerChangeHandler,
    onTimePickerChangeHandler,
    onSelectChangeHandler,
    onCheckboxChanged,
    onCheckAllBoxesChanged,
    review,
    disabled // Make sure this is included in props
}) {
    if (singleField.type === FIELD_TYPES.paragraph) {
        return (
            <FormViewInputTextarea
                singleField={singleField}
                reviewer={review}
                trackPositions={trackPositions}
                onTextChangeHandler={onTextChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.text) {
        return (
            <FormViewInput
                singleField={singleField}
                trackPositions={trackPositions}
                reviewer={review}
                onTextChangeHandler={onTextChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.number) {
        return (
            <FormViewInput
                type={FIELD_TYPES.number}
                singleField={singleField}
                reviewer={review}
                trackPositions={trackPositions}
                onTextChangeHandler={onTextChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.datepicker) {
        return (
            <FormViewDatePicker
                trackPositions={trackPositions}
                singleField={singleField}
                reviewer={review}
                onDatePickerChangeHandler={onDatePickerChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.time) {
        return (
            <FormViewTimePicker
                type={FIELD_TYPES.time}
                trackPositions={trackPositions}
                singleField={singleField}
                reviewer={review}
                onTimePickerChangeHandler={onTimePickerChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.dropdown) {
        return (
            <FormViewDropdown
                trackPositions={trackPositions}
                singleField={singleField}
                reviewer={review}
                onDatePickerChangeHandler={onSelectChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.name) {
        return (
            <FormViewName
                trackPositions={trackPositions}
                singleField={singleField}
                onTextChangeHandler={onTextChangeHandler}
                reviewer={review}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.email) {
        return (
            <FormViewEmail
                trackPositions={trackPositions}
                singleField={singleField}
                reviewer={review}
                onTextChangeHandler={onTextChangeHandler}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.company) {
        return (
            <FormViewCompany
                trackPositions={trackPositions}
                singleField={singleField}
                onTextChangeHandler={onTextChangeHandler}
                reviewer={review}
                disabled={disabled}
            />
        );
    } else if (singleField.type === FIELD_TYPES.checkAllBoxes) {
        return (
            <FormViewCheckAllBoxes
                singleField={singleField}
                trackPositions={trackPositions}
                onCheckAllBoxesChanged={onCheckAllBoxesChanged}
                reviewer={review}
                disabled={disabled}
            />
        );
    } else {
        return null;
    }
}

export default memo(PrintFieldsInBuilderView);

