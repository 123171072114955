import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, notification, Radio } from "antd";
import DraggleWithResize from "Components/DraggableWithResize/DraggableWithResize";
import { saveSingleFieldId, updateField } from "redux/fieldSlice";
import { checkBoxAndRadioBtnGroupByFieldName, FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";

function TemplateBuilderMiddleInner({ currentPageNo }) {
    const dispatch = useDispatch();

    const trackPositions = (data, singleFieldParam) => {
        // Get the position values, handling both drag and keyboard events
        const xPos = typeof data.x === 'number' ? data.x : data.deltaX;
        const yPos = typeof data.y === 'number' ? data.y : data.deltaY;

        // Ensure we have valid numbers
        const x = Math.round(xPos);
        const y = Math.round(yPos);

        console.log('Movement detected:', { x, y, type: data.type || 'drag' });

        dispatch(updateField({
            id: singleFieldParam.id,
            x,
            y
        }));
    };

    const selectFieldHandler = (event, singleFieldParam) => {
        event.preventDefault();

        if (singleFieldParam.type === FIELD_TYPES.checkbox && singleFieldParam.mainCheckbox === false) {
            const cloneFields = UtilService.deepClone(fields);

            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainCheckboxObject = filteredGroupId.find(
                    (singleFieldCheckbox) => singleFieldCheckbox.mainCheckbox
                );
                if (findMainCheckboxObject) {
                    dispatch(saveSingleFieldId(findMainCheckboxObject.id));
                } else {
                    notification.warn({
                        message: "cannot find main checkbox element"
                    });
                }
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else if (singleFieldParam.type === FIELD_TYPES.signature_signed_date) {
            dispatch(saveSingleFieldId(singleFieldParam.parentId));
        } else if (singleFieldParam.type === FIELD_TYPES.radiobtn) {
            const cloneFields = UtilService.deepClone(fields);

            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainRadioObject = filteredGroupId.find((singleFieldRadio) => singleFieldRadio.mainRadio);
                dispatch(saveSingleFieldId(findMainRadioObject.id));
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else {
            dispatch(saveSingleFieldId(singleFieldParam.id));
        }
    };

    const onResizeStart = (e, direction, ref, d, singleFieldParam) => {
        dispatch(
            updateField({
                id: singleFieldParam.id,
                disableDraggable: true
            })
        );
    };

    const onResizeStopHandle = (e, direction, ref, d, singleFieldParam) => {
        dispatch(
            updateField({
                id: singleFieldParam.id,
                disableDraggable: false,
                width: ref.clientWidth,
                height: ref.clientHeight
            })
        );
    };

    const singleFieldId = useSelector((state) => state.fields.singleFieldId);
    const fields = useSelector((state) => state.fields.fields);

    const checkBoxAndRadioAndSignatureGroups = UtilService.groupBy(fields, checkBoxAndRadioBtnGroupByFieldName);

    const findSelectedField = useMemo(
        () => (singleFieldId ? fields.find((singleField) => singleField.id === singleFieldId) : null),
        [singleFieldId, fields]
    );

    return (
        <>
            {fields?.length > 0 &&
                fields
                    .filter((singleFieldFilter) => singleFieldFilter.pageNumber === currentPageNo)
                    .filter((singleFieldFilter) => singleFieldFilter.type !== FIELD_TYPES.checkbox)
                    .filter((singleFieldFilter) => singleFieldFilter.type !== FIELD_TYPES.radiobtn)
                    .filter((singleFieldFilter) => singleFieldFilter.type !== FIELD_TYPES.signature)
                    .filter((singleFieldFilter) => singleFieldFilter.type !== FIELD_TYPES.signature_signed_date)
                    ?.map((singleField) => {
                        const middleDraggleWithResizeProps = {
                            singleField,
                            onResizeStopHandle,
                            onResizeStart,
                            trackPositions,
                            selectFieldHandler
                        };

                        let selectedClassName = "";

                        if (findSelectedField?.id === singleField?.id) {
                            selectedClassName =
                                singleField.groupId === findSelectedField.groupId
                                    ? "resize-able-item-custom-selected"
                                    : "";
                        }

                        return (
                            <DraggleWithResize
                                {...middleDraggleWithResizeProps}
                                className={selectedClassName}
                                key={singleField.id}
                            >
                                <span
                                    style={{
                                        fontSize: singleField.fontSize,
                                        color: singleField.textColor
                                    }}
                                >
                                    {singleField.placeholder || singleField.type}
                                </span>
                            </DraggleWithResize>
                        );
                    })}

            {/* SIGNATURE WITH DATE SIGNED */}
            {Object.keys(checkBoxAndRadioAndSignatureGroups).map((key) => {
                const fieldsOfCheckBoxGroups = checkBoxAndRadioAndSignatureGroups[key];
                return (
                    <React.Fragment key={key}>
                        {fieldsOfCheckBoxGroups?.length > 0 &&
                            fieldsOfCheckBoxGroups
                                .filter((singleFieldFilter) => singleFieldFilter.pageNumber === currentPageNo)
                                .filter(
                                    (singleFieldFilter) =>
                                        singleFieldFilter.type === FIELD_TYPES.signature ||
                                        singleFieldFilter.type === FIELD_TYPES.signature_signed_date
                                )
                                ?.map((singleField) => {
                                    const middleDraggleWithResizeProps = {
                                        singleField,
                                        onResizeStopHandle,
                                        onResizeStart,
                                        trackPositions,
                                        selectFieldHandler
                                    };
                                    let selectedClassName = "";

                                    if (
                                        findSelectedField?.type === FIELD_TYPES.signature ||
                                        findSelectedField?.type === FIELD_TYPES.signature_signed_date
                                    ) {
                                        selectedClassName =
                                            singleField.groupId === findSelectedField.groupId
                                                ? "resize-able-item-custom-selected"
                                                : "";
                                    }

                                    return (
                                        <DraggleWithResize
                                            {...middleDraggleWithResizeProps}
                                            className={selectedClassName}
                                            key={singleField.id}
                                        >
                                            <span
                                                style={{
                                                    fontSize: singleField.fontSize,
                                                    color: singleField.textColor
                                                }}
                                            >
                                                {singleField.placeholder || singleField.type}
                                            </span>
                                        </DraggleWithResize>
                                    );
                                })}
                    </React.Fragment>
                );
            })}

            {/* CHECKBOX */}
            {Object.keys(checkBoxAndRadioAndSignatureGroups).map((key) => {
                const fieldsOfCheckBoxGroups = checkBoxAndRadioAndSignatureGroups[key];
                return (
                    <React.Fragment key={key}>
                        {fieldsOfCheckBoxGroups?.length > 0 &&
                            fieldsOfCheckBoxGroups
                                .filter((singleFieldFilter) => singleFieldFilter.pageNumber === currentPageNo)
                                .filter((singleFieldFilter) => singleFieldFilter.type === FIELD_TYPES.checkbox)
                                ?.map((singleField) => {
                                    const middleDraggleWithResizeProps = {
                                        singleField,
                                        onResizeStopHandle,
                                        onResizeStart,
                                        trackPositions,
                                        selectFieldHandler
                                    };
                                    let selectedClassName = "";

                                    if (findSelectedField?.type === FIELD_TYPES.checkbox) {
                                        selectedClassName =
                                            singleField.groupId === findSelectedField.groupId
                                                ? "resize-able-item-custom-selected"
                                                : "";
                                    }

                                    return (
                                        <DraggleWithResize
                                            {...middleDraggleWithResizeProps}
                                            className={`${selectedClassName} checkbox-item`}
                                            key={singleField.id}
                                        >
                                            <Checkbox
                                                style={{
                                                    lineHeight: "32px",
                                                    paddingleft: "8px"
                                                }}
                                                checked={singleField.value}
                                                onClick={(e) => e.preventDefault()}
                                            />
                                        </DraggleWithResize>
                                    );
                                })}
                    </React.Fragment>
                );
            })}

            {/* RADIO BUTTON */}
            {Object.keys(checkBoxAndRadioAndSignatureGroups).map((key) => {
                const fieldsOfradioButtonGroups = checkBoxAndRadioAndSignatureGroups[key];

                return (
                    <React.Fragment key={key}>
                        {fieldsOfradioButtonGroups?.length > 0 &&
                            fieldsOfradioButtonGroups
                                .filter((singleFieldFilter) => singleFieldFilter.pageNumber === currentPageNo)
                                .filter((singleFieldFilter) => singleFieldFilter.type === FIELD_TYPES.radiobtn)
                                ?.map((singleField) => {
                                    const middleDraggleWithResizeProps = {
                                        singleField,
                                        onResizeStopHandle,
                                        onResizeStart,
                                        trackPositions,
                                        selectFieldHandler
                                    };

                                    let selectedClassName = "";

                                    if (findSelectedField?.type === FIELD_TYPES.radiobtn) {
                                        selectedClassName =
                                            singleField.groupId === findSelectedField.groupId
                                                ? "resize-able-item-custom-selected"
                                                : "";
                                    }

                                    return (
                                        <DraggleWithResize
                                            {...middleDraggleWithResizeProps}
                                            className={`${selectedClassName} radiobtn-item`}
                                            key={singleField.id}
                                        >
                                            <Radio
                                                style={{
                                                    lineHeight: "32px",
                                                    paddingLeft: "8px"
                                                }}
                                                checked={singleField.value}
                                                onClick={(e) => e.preventDefault()}
                                            />
                                        </DraggleWithResize>
                                    );
                                })}
                    </React.Fragment>
                );
            })}
        </>
    );
}

export default TemplateBuilderMiddleInner;

