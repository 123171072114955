/* eslint-disable react-hooks/exhaustive-deps */
import BuilderPageContainer from "Components/BuilderPageContainer/BuilderPageContainer";
import CustomDropComponent from "Components/CustomDropComponent/CustomDropComponent";
import CustomSignature from "Components/CustomSignature/CustomSignature";
import { defaultFieldObject } from "Components/TemplateBuilderWrapper/FormFields/constant";
import { Button, Checkbox, Radio, message } from "antd";
import moment from "moment";
import {
    useQueryEnvelopebyPassedRoute,
    useQueryGetEnvelopeById,
    useQueryUpdateEnvelope
} from "queries/envelope.queries";
import { useQueryGetTemplateById } from "queries/template.queries";
import React, { memo, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { saveSignerField } from "redux/signerFieldSlice";
import {
    ACTIONS_PARAM_NAMES_ENVELOPES,
    ActionsTypesForGuestRoutes,
    EnvelopeActionStatus,
    FIELD_TYPES,
    FINISH_LATER_BTN_CHECK,
    checkBoxAndRadioBtnGroupByFieldName,
    envelopeActionEnum,
    envelopeHeaderKey,
    normalRoutesConstant,
    validationErrorStatus
} from "util/util.constant";
import { UtilService } from "util/util.service";
import { setTabData, signData } from "../../redux/tabSlice";
import useField from "../../redux/useField";
import "./EnvelopeFormViewInner.less";
import FormViewCheckbox from "./FormViewFields/FormViewCheckbox/FormViewCheckbox";
import FormViewRadio from "./FormViewFields/FormViewRadio/FormViewRadio";
import FormViewSignature from "./FormViewFields/FormViewSignature/FormViewSignature";
import FormViewSignedDate from "./FormViewFields/FormViewSignedDate/FormViewSignedDate";
import PrintFieldsInBuilderView from "./PrintFieldsInBuilderView";

function EnvelopeFormViewInner({ currentPdfObject, setCurrentPdfObject }) {
    const dispatch = useDispatch();

    const getSigningLocalStorageData = useSelector(signData);

    const {
        fields,
        radioOptionOnChangeCheckedEnvelopeView,
        updateField,
        updateAllFields,
        updateQueryParamsToFields,
        updateSignatureFormValue,
        handleCheckAllBoxes
    } = useField();
    const [signaturePopup, setSignaturePopup] = useState(false);
    const [signatureField, setSignatureField] = useState(null);
    const [firstSign, setFirstSign] = useState(null);
    const [allRecentSign, setAllRecentSign] = useState([]);
    const [, setSelectedRecentSignValue] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [, setSignFields] = useState([]);
    const isDraftEnvelope = useRef(null);
    const { template_id, envelope_id } = useParams();
    const location = useLocation();
    const scrollPosition = useRef(null);
    const checkboxRef = useRef([]);
    const oneSecond = 1000;
    const oneMinute = oneSecond * 60;
    const autoSaveTime = process.env.REACT_APP_FORM_AUTOSAVE_TIME * oneMinute;
    const timeoutIdRef = useRef(null);
    const customFinishBtnLoader = useRef(false);
    const updateCloneAction = useRef(true);
    const wide = useWindowWide(600);
    const {
        refresh_token,
        x_envelope,
        env: envelopeIdParam,
        action: actionParam,
        reviewer,
        client_api_key = "",
        send_email,
        restrictions = "",
        showButtons,
        webhookData,
        data
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);
    const review = reviewer && JSON.parse(reviewer);
    const decodedWebhookData = webhookData && JSON.parse(atob(webhookData));
    const signerFields = useSelector((state) => state.signerFields.signerFields);
    const signingData = useMemo(() => {
        return getSigningLocalStorageData !== null || getSigningLocalStorageData !== ""
            ? getSigningLocalStorageData
            : false;
    }, [getSigningLocalStorageData]);

    const checkUserEnvelopeGeneratedFormView = useMemo(
        () => signingData && Boolean(refresh_token) && Boolean(x_envelope),
        [signingData, refresh_token, x_envelope]
    );

    const templateIdConfirmed = useMemo(
        () => (checkUserEnvelopeGeneratedFormView ? signingData?.template?.id : template_id),
        [checkUserEnvelopeGeneratedFormView, signingData, template_id]
    );

    const {
        data: singleEnvelope,
        isLoading: getEnvelopeByIdLoading,
        isFetching: getEnvelopeByIdFetching
    } = useQueryGetEnvelopeById(envelope_id, () => {}, checkUserEnvelopeGeneratedFormView);

    const {
        data: singleTemplateApiData,
        isFetching: getTemplateByIdFetching,
        isLoading: getTemplateByIdLoading
    } = useQueryGetTemplateById(templateIdConfirmed, () => {}, Boolean(checkUserEnvelopeGeneratedFormView));

    const singleTemplateLocalStorage = useMemo(() => {
        return signingData?.template;
    }, [signingData?.template]);

    const singleTemplate = useMemo(() => {
        return checkUserEnvelopeGeneratedFormView ? singleTemplateLocalStorage : singleTemplateApiData;
    }, [singleTemplateLocalStorage, singleTemplateApiData]);

    const { mutateAsync: updateEnvelopeMutateQuery, isLoading: updateEnvelopeLoading } = useQueryUpdateEnvelope();
    const { mutateAsync: createEnvelopeByPassedRouteMutateQuery, isLoading: updateEnvelopeByPassedLoading } =
        useQueryEnvelopebyPassedRoute();

    const updateQueryFieldsData = (tabData) => {
        if (data) {
            const queryNameField = UtilService.base64toArray(data);

            const findNameObject = queryNameField?.find((singleQuery) => singleQuery?.queryName === "name");
            const findSignerNameObject = queryNameField?.find((singleQuery) => singleQuery?.queryName === "signerName");

            const findEmailObject = queryNameField?.find((singleQuery) => singleQuery?.queryName === "email");
            const createPayloadForSignerField = {
                signerName: findSignerNameObject?.value,
                name: findNameObject?.value,
                email: findEmailObject?.value
            };

            dispatch(saveSignerField(createPayloadForSignerField));

            updateQueryParamsToFields(queryNameField, tabData);
        } else {
            updateAllFields(tabData);
        }
    };

    useEffect(() => {
        if (signingData?.envelope?.attributes?.envelopeDetail?.tabs) {
            const convertedTabData = signingData?.envelope?.attributes?.envelopeDetail?.tabs;

            if (convertedTabData?.length > 0) {
                const tabData = convertedTabData.map((singleField) => {
                    return {
                        ...defaultFieldObject,
                        ...singleField
                    };
                });
                dispatch(setTabData(tabData));
                updateAllFields(tabData);
            }
        } else if (singleEnvelope) {
            console.log("singleEnvelope", singleEnvelope);

            const convertedTabData = singleEnvelope?.attributes?.templateDetail?.tabs;

            if (convertedTabData?.length > 0) {
                const tabData = convertedTabData.map((singleField) => {
                    return {
                        ...defaultFieldObject,
                        ...singleField
                    };
                });
                dispatch(setTabData(tabData));

                updateAllFields(tabData);
            }
        } else if (checkUserEnvelopeGeneratedFormView && singleTemplate) {
            console.log("checkUserEnvelopeGeneratedFormView && singleTemplate", singleTemplate);
            const convertedTabData = singleTemplate?.attributes?.templateDetail?.tabs;

            if (convertedTabData?.length > 0) {
                const tabData = convertedTabData.map((singleField) => {
                    return {
                        ...defaultFieldObject,
                        ...singleField
                    };
                });
                dispatch(setTabData(tabData));

                updateAllFields(tabData);
            }
        }
    }, []);

    useEffect(() => {
        if (signingData?.envelope?.attributes?.envelopeDetail?.tabs) {
            const convertedTabData = signingData?.envelope?.attributes?.envelopeDetail?.tabs;

            if (convertedTabData?.length > 0) {
                const tabData = convertedTabData.map((singleField) => {
                    return {
                        ...defaultFieldObject,
                        ...singleField
                    };
                });
                updateQueryFieldsData(tabData);
            }
        }
    }, [signingData?.envelope?.attributes?.envelopeDetail?.tabs]);

    useEffect(() => {
        if (singleEnvelope) {
            const convertedTabData = singleEnvelope?.attributes?.templateDetail?.tabs;

            if (convertedTabData?.length > 0) {
                const tabData = convertedTabData.map((singleField) => {
                    return {
                        ...defaultFieldObject,
                        ...singleField
                    };
                });
                updateQueryFieldsData(tabData);
            }
        }
    }, [singleEnvelope, getEnvelopeByIdLoading, getEnvelopeByIdFetching]);

    useEffect(() => {
        if (checkUserEnvelopeGeneratedFormView && singleTemplate) {
            const convertedTabData = singleTemplate?.attributes?.templateDetail?.tabs;
            if (convertedTabData) {
                if (convertedTabData?.length > 0) {
                    const tabData = convertedTabData.map((singleField) => {
                        return {
                            ...defaultFieldObject,
                            ...singleField
                        };
                    });
                    updateQueryFieldsData(tabData);
                }
            }
        }
    }, [singleTemplate]);

    const trackPositions = (data, singleFieldParam) => {
        updateField({
            id: singleFieldParam.id,
            x: data?.x,
            y: data?.y
        });
    };

    const onTextChangeHandler = (event, singleFieldParam, autoComplete = false) => {
        let value = "";

        if (autoComplete) {
            //this is not event it will pass the value direct
            value = event;
        } else {
            value = event?.target?.value;
        }

        updateField({
            id: singleFieldParam.id,
            value,
            validationStatus: ""
        });
    };

    const onRadioBtnChanged = (event, singleRepeaterField) => {
        const {
            target: { value }
        } = event;

        radioOptionOnChangeCheckedEnvelopeView({ singleRepeaterField, value });
    };

    const [checkedValues, setCheckedValues] = useState([]);

    // Add this effect to sync checkedValues with fields
    useEffect(() => {
        const checkedBoxes = fields
            .filter(field => field.type === FIELD_TYPES.checkbox && (field.checked || field.value))
            .map(field => field.id);
        setCheckedValues(checkedBoxes);
    }, [fields]);

    const onCheckboxChanged = (event, singleFieldParam) => {
        const {
            target: { checked }
        } = event;

        // Update Redux state first
        updateField({
            id: singleFieldParam.id,
            value: checked,
            checked: checked,
            validationStatus: ""
        });

        // Update local state for Checkbox.Group
        setCheckedValues(prev => {
            if (checked) {
                return [...prev, singleFieldParam.id];
            }
            return prev.filter(id => id !== singleFieldParam.id);
        });

        // Update checkboxRef
        if (checkboxRef?.current) {
            const newRef = [...(checkboxRef.current || [])];
            const findIndex = newRef.findIndex(field => field.id === singleFieldParam.id);
            
            if (findIndex !== -1) {
                newRef[findIndex] = {
                    ...newRef[findIndex],
                    value: checked,
                    checked: checked
                };
            } else {
                newRef.push({
                    ...singleFieldParam,
                    value: checked,
                    checked: checked
                });
            }
            checkboxRef.current = newRef;
        }
    };

    const onCheckAllBoxesChanged = useCallback((event, field) => {
        const { checked } = event.target;
        
        // Update Redux state first
        handleCheckAllBoxes(checked);
        
        // Update local state for Checkbox.Group
        const checkboxFields = fields.filter(f => f.type === FIELD_TYPES.checkbox);
        const checkboxIds = checkboxFields.map(f => f.id);
        
        setCheckedValues(checked ? checkboxIds : []);
        
        // Update checkboxRef
        if (checkboxRef?.current) {
            checkboxRef.current = checkboxFields.map(checkbox => ({
                ...checkbox,
                value: checked,
                checked: checked
            }));
        }
    }, [handleCheckAllBoxes, fields]);

    const onSelectChangeHandler = (option, singleFieldParam) => {
        updateField({
            id: singleFieldParam.id,
            value: option,
            validationStatus: ""
        });
    };

    const onDatePickerChangeHandler = (date, dateString, singleFieldParam) => {
        updateField({
            id: singleFieldParam.id,
            value: dateString,
            validationStatus: ""
        });
    };
    const onTimePickerChangeHandler = (time, timeString, singleFieldParam) => {
        updateField({
            id: singleFieldParam.id,
            // value: UtilService.timePickerFormatEnvelope(time),
            value: timeString,
            validationStatus: ""
        });
    };

    const addSignature = (signatureValue) => {
        setFirstSign(signatureValue);
        const currentDate = moment().format("MM-DD-yyyy h:mm a");
        const queryNameFields = UtilService.base64toArray(data);
        const findSignerEmail = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
        )?.value;

        const findSignerName = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerName
        )?.value;

        updateSignatureFormValue({
            id: signatureField.id,
            value: signatureValue,
            validationStatus: "",
            currentDateTime: currentDate,
            signerEmail: findSignerEmail || "",
            signerName: findSignerName || ""
        });
    };

    const addInitialSignature = (signatureValue, signatureFieldInitial) => {
        const currentDate = new Date();

        const queryNameFields = UtilService.base64toArray(data);
        const findSignerEmail = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
        )?.value;

        const findSignerName = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerName
        )?.value;

        updateSignatureFormValue({
            id: signatureFieldInitial?.id,
            value: signatureValue,
            validationStatus: "",
            currentDateTime: currentDate,
            email: findSignerEmail || "",
            signerName: findSignerName || ""
        });
    };

    const getRecentSignatures = () => {
        const fieldsCloneImg = [...fields];
        const findSignatureFieldImg = fieldsCloneImg.filter(
            (singleField) => singleField?.type === FIELD_TYPES.signature && singleField?.value !== ""
        );

        if (findSignatureFieldImg?.length > 0) {
            const filteruniquebyValue = findSignatureFieldImg.filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            );

            setSignFields(filteruniquebyValue);
        }
    };

    const onFormFinish = async (event, btnStatus = "", isAutoSave = false) => {
        if (customFinishBtnLoader.current) return;
        if (event) {
            event?.preventDefault();
        }
        customFinishBtnLoader.current = true;
        onFinishFunctionFire(btnStatus, isAutoSave);
    };

    const onFinishFunctionFire = (btnStatus = "", isAutoSave) => {
        if (isAutoSave) {
            clearTimeout(timeoutIdRef.current);
        }
        const isFinishLaterBtn = Boolean(btnStatus === FINISH_LATER_BTN_CHECK);
        let fieldClone = [];
        if (checkboxRef?.current?.length > 0) {
            const withoutCheckbox = UtilService.deepClone(fields)?.filter(
                (items) => items?.type !== FIELD_TYPES.checkbox
            );

            fieldClone = [...withoutCheckbox, ...checkboxRef?.current];
        } else {
            fieldClone = UtilService.deepClone(fields);
        }

        const requiredFields = fieldClone.filter((singleField) => singleField.required);

        const allValuesAreNotRequired = fieldClone
            .map(
                (singleRequiredField) =>
                    singleRequiredField.type !== FIELD_TYPES.checkbox ||
                    singleRequiredField.type !== FIELD_TYPES.radiobtn
            )
            .every((singleRequiredField) => {
                return !singleRequiredField.required;
            });

        if (allValuesAreNotRequired || isFinishLaterBtn) {
            updateEnvelopeCall(fieldClone, isFinishLaterBtn, isAutoSave);
        } else {
            if (requiredFields.length > 0) {
                requiredFields.forEach((singleField, index) => {
                    if (singleField.value === "") {
                        if (singleField.type === FIELD_TYPES.text) {
                            message.warning(`${singleField.placeholder} ${singleField.type} is required`);
                        } else {
                            message.warning(`${singleField.type} is required`);
                        }
                        requiredFields[index].validationStatus = validationErrorStatus;
                    }
                });

                const valuesAreFilled = requiredFields
                    .map(
                        (singleRequiredField) =>
                            singleRequiredField.type !== FIELD_TYPES.checkbox ||
                            singleRequiredField.type !== FIELD_TYPES.radiobtn
                    )
                    .every((singleRequiredField) => {
                        return singleRequiredField.value !== "";
                    });

                if (valuesAreFilled) {
                    updateEnvelopeCall(fieldClone, false, isAutoSave);
                }
                updateAllFields(fieldClone);
            }
        }
    };
    useEffect(() => {
        if (fields && fields.length > 0) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = setTimeout(() => {
                onFormFinish("", "", true);
            }, autoSaveTime);
        }

        return () => {
            clearTimeout(timeoutIdRef.current);
        };
        // add this dependency if want to make timeout.
        // onFinishFunctionFire
    }, [fields, checkboxRef, buttonDisable, autoSaveTime]);

    const updateEnvelopeCall = (tabsFields, isFinishLaterBtn = false, isAutoSave = false) => {
        if (showButtons == 1) {
            return;
        }

        let newTabFields = tabsFields.map((singleTabField) => {
            if (singleTabField.type === FIELD_TYPES.signature) {
                return {
                    ...singleTabField,
                    value: singleTabField.value
                };
            }
            return {
                ...singleTabField
            };
        });

        //Find Email And Name From Query Params
        let findNameField;
        let findEmailField;

        if (data) {
            const queryNameFields = UtilService.base64toArray(data);
            findNameField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerName
            )?.value;
            findEmailField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
            )?.value;
        }

        if (checkUserEnvelopeGeneratedFormView) {
            const headers = [
                {
                    headerKey: envelopeHeaderKey.refreshToken,
                    headerValue: refresh_token
                },
                {
                    headerKey: envelopeHeaderKey.xEnvelope,
                    headerValue: isDraftEnvelope.current?.x_envelope ?? x_envelope
                },
                {
                    headerKey: envelopeHeaderKey.clientApiKey,
                    headerValue: client_api_key
                }
            ];

            const payload = {
                tabs: newTabFields,
                name: `${signerFields?.payload?.signerName ?? ""} ${singleTemplate?.id}`,
                email: signerFields?.payload?.email,
                action: envelopeActionEnum.envelopeFinished,
                webhook_data: decodedWebhookData,
                envelopeAction: isDraftEnvelope.current?.check
                    ? ActionsTypesForGuestRoutes.UPDATE_ENVELOPE
                    : ActionsTypesForGuestRoutes.CREATE_ENVELOPE
            };

            if (isFinishLaterBtn) {
                payload.action = envelopeActionEnum.EnvelopeFinishLater;
            }

            if (Boolean(envelopeIdParam) && Boolean(actionParam)) {
                payload.name = signingData?.attributes?.name;
                payload.email = signingData?.attributes?.email;

                if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE && updateCloneAction.current) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE;
                    updateCloneAction.current = false;
                } else if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE;
                }
            }

            if (findNameField) {
                payload.name = findNameField;
            }
            if (findEmailField) {
                payload.email = findEmailField;
            }

            if (isAutoSave) {
                payload.statusAction = EnvelopeActionStatus.DRAFT;
                payload.action = envelopeActionEnum.EnvelopeFinishLater;
                isDraftEnvelope.current = {
                    ...isDraftEnvelope.current,
                    check: true
                };
            }

            const ifTabValueIsExist = ifTabValueIsExistCheck(payload);

            if (ifTabValueIsExist) {
                try {
                    setButtonDisable(true);

                    createEnvelopeByPassedRouteMutateQuery(
                        { payload, headers },
                        {
                            onSuccess: (data) => {
                                createEnvelopeSuccess(data, isAutoSave);

                                const authorizeToken = data?.attributes?.authorizeToken;

                                if (isAutoSave && Boolean(authorizeToken)) {
                                    isDraftEnvelope.current = {
                                        ...isDraftEnvelope.current,
                                        x_envelope: authorizeToken
                                    };
                                }
                            },
                            onSettled: (data) => {
                                customFinishBtnLoader.current = false;
                            }
                        }
                    );
                } catch (error) {
                    console.log("error", error);
                } finally {
                    setButtonDisable(false);
                }
            } else {
                if (!isAutoSave) {
                    console.log("while creating the envelope");
                    message.warn("tabs data is empty");
                }
            }
        } else {
            //this else will only in envelope form view not generated form view
            const payload = {
                template_id: template_id,
                tabs: newTabFields,
                name: singleEnvelope?.name
            };

            if (findNameField) {
                payload.name = findNameField;
            }
            if (findEmailField) {
                payload.email = findEmailField;
            }

            const ifTabValueIsExist = ifTabValueIsExistCheck(payload);

            if (ifTabValueIsExist) {
                updateEnvelopeMutateQuery(
                    { payload, envelopeIdParam: envelope_id },
                    {
                        onSuccess: () => updateEnvelopeSuccess()
                    }
                );
            } else {
                console.log("while updating the envelope");
                message.warn("tabs data is empty");
            }
        }
    };

    const ifTabValueIsExistCheck = (payload) => {
        const tabs = [...payload?.tabs];

        if (tabs?.length === 0 || !tabs) {
            return false;
        }

        const valuesAreFilled = tabs
            .map(
                (singleRequiredField) =>
                    singleRequiredField.type !== FIELD_TYPES.checkbox ||
                    singleRequiredField.type !== FIELD_TYPES.radiobtn
            )
            .every((singleRequiredField) => {
                return singleRequiredField.value !== "";
            });

        return valuesAreFilled;
    };

    const updateEnvelopeSuccess = () => {
        message.success("envelope is updated successfully!");
    };

    const createEnvelopeSuccess = (data, isAutoSave = false) => {
        message.success(`envelope is  ${isAutoSave ? "auto " : ""}saved successfully!`);

        if (!isAutoSave) {
            const connectReturnUrl = data?.attributes?.connectReturnUrl;
            const addParams = `?env=${data?.attributes?.identity_key}`;
            const connectReturnUrlTwo = `${connectReturnUrl}${addParams}`;
            const connectReturnUrlThree = `${normalRoutesConstant.ThankYou}${addParams}`;
            if (Boolean(send_email)) {
                window.location.href = connectReturnUrlThree;
            } else {
                const exactRedirectUrl = connectReturnUrl ? connectReturnUrlTwo : connectReturnUrlThree;
                window.location.href = exactRedirectUrl;
            }
        }
    };
    const addFirstSign = (event, singleField) => {
        event.preventDefault();
        const queryNameFields = UtilService.base64toArray(data);
        const currentDate = new Date();
        const findSignerEmail = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
        )?.value;

        const findSignerName = queryNameFields?.find(
            (singleField) => singleField.queryName === FIELD_TYPES.signerName
        )?.value;
        updateSignatureFormValue({
            id: singleField?.id,
            value: firstSign,
            validationStatus: "",
            currentDateTime: currentDate,
            email: findSignerEmail || "",
            signerName: findSignerName || ""
        });
    };

    const showSignatureModal = (event, singleField) => {
        event.preventDefault();
        setSignaturePopup(true);
        setSignatureField(singleField);
        getRecentSignatures();
    };

    const handleMouseEvents = (event, currentPageNo = 1) => {
        if (currentPdfObject?.pageNumber != currentPageNo) {
            if (event.nativeEvent.target.tagName !== "TEXTAREA") {
                event.preventDefault();
                setCurrentPdfObject({ ...currentPdfObject, pageNumber: currentPageNo });
            } else {
                event.stopPropagation();
                setCurrentPdfObject({ ...currentPdfObject, pageNumber: currentPageNo });
            }
        }
    };

    const checkBoxAndRadioAndSignedGroups = UtilService.groupBy(fields, checkBoxAndRadioBtnGroupByFieldName);
    const scrollEvent = (e) => {
        scrollPosition.current = e.target.scrollTop;
    };

    const customDropComponentProps = {
        currentPdfObject,
        position: scrollPosition.current
    };

    const templateImages =
        Boolean(envelopeIdParam) && Boolean(actionParam)
            ? signingData?.envelope?.attributes?.template?.attributes?.templateImages
            : singleTemplate?.attributes?.templateImages;

    const onRadioBtnClickHandler = (event, singleField) => {
        event.preventDefault();
        const { value, id: singleFieldReduxId } = singleField;

        if (value) {
            updateField({
                id: singleFieldReduxId,
                value: "",
                checked: false,
                validationStatus: ""
            });
        }
    };

    // / 612
    const screenWidth = (window.innerWidth * 0.8 * (window.innerWidth > 880 ? 0.7 : 1)) / 612;
    const height = (821 * screenWidth * templateImages?.length || 1) + 100 * screenWidth;
    console.log(window.innerWidth, window.innerWidth > 880 ? 0.7 : 1);
    return (
        <CustomDropComponent {...customDropComponentProps}>
            <div
                id={
                    showButtons == 1
                        ? "envelope-view-only-builder-middle-side-main-container"
                        : "envelope-builder-middle-side-main-container"
                }
                className={`${
                    location.pathname === normalRoutesConstant.EnvelopeUserFormView ? "" : "form-view-inner-sec"
                }`}
                onScrollCapture={scrollEvent}
            >
                {showButtons != 1 && (
                    <div
                        id="finish-btn-sec"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: wide ? "row" : "column",
                            gap: "1rem",
                            margin: "12px auto",
                            textAlign: "center",
                            maxWidth: "612px",
                            position: "sticky",
                            top: 0,
                            zIndex: 10,
                            padding: "9px 12px",
                            background: "rgba(255,255,255,0.4)",
                            borderRadius: "2px",
                            boxShadow: "0 4px 30px rgba(0,0,0,0.1)",
                            backdropFilter: "blur(5px)",
                            border: "1px solid rgba(255,255,255,0.3)",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                color: "black",
                                background: "#fffbe6",
                                padding: "8px 8px",
                                borderRadius: 5,
                                border: "1px solid #ffe58f",
                                fontSize: "11px",
                                letterSpacing: "-0.04em"
                            }}
                        >
                            *if your are working with large data please make sure that you save occasionally
                        </div>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                type="primary"
                                disabled={buttonDisable}
                                onClick={onFormFinish}
                                className="common-button envelope-btn"
                                loading={
                                    getEnvelopeByIdLoading ||
                                    updateEnvelopeLoading ||
                                    updateEnvelopeByPassedLoading ||
                                    getTemplateByIdFetching ||
                                    getTemplateByIdLoading ||
                                    customFinishBtnLoader.current
                                }
                            >
                                Finish
                            </Button>

                            {!review && !send_email && (
                                <>
                                    {restrictions !== "1" && (
                                        <Button
                                            type="primary"
                                            onClick={(event) => onFormFinish(event, FINISH_LATER_BTN_CHECK)}
                                            className="common-button envelope-btn "
                                            disabled={
                                                getEnvelopeByIdLoading ||
                                                updateEnvelopeLoading ||
                                                updateEnvelopeByPassedLoading ||
                                                getTemplateByIdFetching ||
                                                getTemplateByIdLoading ||
                                                customFinishBtnLoader.current ||
                                                buttonDisable
                                            }
                                        >
                                            Finish Later
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div
                    style={{
                        transformOrigin: `${window.innerWidth < 500 ? window.innerWidth / 20 + "0%" : "center"}  top `,
                        transform: screenWidth > 1 && `scale(${screenWidth}) `,
                        paddingBottom: 100,
                        height: screenWidth < 1 && height,
                        width: 612,
                        margin: "0 auto"
                    }}
                >
                    {templateImages?.map((items, index) => {
                        const currentPageNo = index + 1;
                        const imageUrl = items?.images?.path;
                        const builderProps = { currentPageNo, handleMouseEvents, imageUrl };
                        return (
                            <BuilderPageContainer {...builderProps} isSignatureDeleteEnable key={items?.id}>
                                {UtilService.orderByDescFields(fields)
                                    ?.filter((singleFieldFilter) => singleFieldFilter.pageNumber === currentPageNo)
                                    .map((singleField) => {
                                        const printFieldsInBuilderViewProps = {
                                            singleField,
                                            trackPositions,
                                            onTextChangeHandler,
                                            showSignatureModal,
                                            onDatePickerChangeHandler,
                                            onSelectChangeHandler,
                                            onTimePickerChangeHandler,
                                            review,
                                            onCheckboxChanged,
                                            onCheckAllBoxesChanged,
                                            disabled: showButtons === 1
                                        };

                                        return (
                                            <PrintFieldsInBuilderView
                                                key={singleField.id}
                                                {...printFieldsInBuilderViewProps}
                                            />
                                        );
                                    })}

                                {/* SIGNATURE WITH DATE SIGNED */}
                                {Object.keys(checkBoxAndRadioAndSignedGroups).map((key) => {
                                    const fieldsOfCheckBoxGroups = checkBoxAndRadioAndSignedGroups[key];
                                    return (
                                        <React.Fragment key={key}>
                                            {fieldsOfCheckBoxGroups?.length > 0 &&
                                                fieldsOfCheckBoxGroups
                                                    .filter(
                                                        (singleFieldFilter) =>
                                                            singleFieldFilter.pageNumber === currentPageNo
                                                    )
                                                    .filter(
                                                        (singleFieldFilter) =>
                                                            singleFieldFilter.type === FIELD_TYPES.signature ||
                                                            singleFieldFilter.type === FIELD_TYPES.signature_signed_date
                                                    )
                                                    ?.map((singleField) => {
                                                        if (singleField.type === FIELD_TYPES.signature) {
                                                            return (
                                                                <FormViewSignature
                                                                    trackPositions={trackPositions}
                                                                    singleField={singleField}
                                                                    showSignatureModal={showSignatureModal}
                                                                    key={singleField.id}
                                                                    addSignature={addSignature}
                                                                    firstSign={firstSign}
                                                                    addFirstSign={addFirstSign}
                                                                    addInitialSignature={addInitialSignature}
                                                                    disabled={showButtons == 1}
                                                                    fields={fields}
                                                                    updateField={updateField}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <FormViewSignedDate
                                                                    key={singleField.id}
                                                                    // trackPositions={trackPositions}
                                                                    singleField={singleField}
                                                                    fieldsOfCheckBoxGroups={fieldsOfCheckBoxGroups}
                                                                />
                                                            );
                                                        }
                                                    })}
                                        </React.Fragment>
                                    );
                                })}

                                {/* CHECKBOX */}
                                {Object.keys(checkBoxAndRadioAndSignedGroups).map((key) => {
                                    const fieldsOfCheckBoxGroups = checkBoxAndRadioAndSignedGroups[key];
                                    
                                    return (
                                        <React.Fragment key={key}>
                                            <Checkbox.Group 
                                                value={checkedValues}
                                                disabled={showButtons == 1}
                                            >
                                                {fieldsOfCheckBoxGroups
                                                    ?.filter(field => 
                                                        field.pageNumber === currentPageNo && 
                                                        field.type === FIELD_TYPES.checkbox
                                                    )
                                                    ?.map((singleField) => (
                                                        <FormViewCheckbox
                                                            key={singleField.id}
                                                            singleField={{
                                                                ...singleField,
                                                                checked: checkedValues.includes(singleField.id)
                                                            }}
                                                            trackPositions={trackPositions}
                                                            onCheckboxChanged={onCheckboxChanged}
                                                            reviewer={review}
                                                        />
                                                    ))}
                                            </Checkbox.Group>
                                        </React.Fragment>
                                    );
                                })}

                                {/* RADIO BUTTON */}
                                {Object.keys(checkBoxAndRadioAndSignedGroups).map((key) => {
                                    const fieldsOfRadioButtonGroups = checkBoxAndRadioAndSignedGroups[key];
                                    let radioButton = fieldsOfRadioButtonGroups.filter(
                                        (singleFieldFilter) => singleFieldFilter.type === FIELD_TYPES.radiobtn
                                    );
                                    const value = radioButton?.find(
                                        (item) => item?.value !== false && item?.value !== ""
                                    );
                                    let errorStatus = fieldsOfRadioButtonGroups
                                        .filter((singleFieldFilter) => singleFieldFilter.type === FIELD_TYPES.radiobtn)
                                        .some((singleFieldFilter) => singleFieldFilter.validationStatus);
                                    const errorClass = errorStatus ? "ant-select-status-error" : "";

                                    return (
                                        <React.Fragment key={key}>
                                            <Radio.Group value={value?.value} disabled={showButtons == 1}>
                                                {fieldsOfRadioButtonGroups?.length > 0 &&
                                                    fieldsOfRadioButtonGroups
                                                        .filter(
                                                            (singleFieldFilter) =>
                                                                singleFieldFilter.pageNumber === currentPageNo
                                                        )
                                                        .filter(
                                                            (singleFieldFilter) =>
                                                                singleFieldFilter.type === FIELD_TYPES.radiobtn
                                                        )
                                                        ?.map((singleField) => {
                                                            return (
                                                                <FormViewRadio
                                                                    key={singleField.id}
                                                                    singleField={singleField}
                                                                    trackPositions={trackPositions}
                                                                    onRadioBtnChanged={onRadioBtnChanged}
                                                                    onRadioBtnClickHandler={onRadioBtnClickHandler}
                                                                    errorClassName={errorClass}
                                                                    reviewer={review}
                                                                    disabled={showButtons == 1}
                                                                    // value={vale[0]?.value}
                                                                />
                                                            );
                                                        })}
                                            </Radio.Group>
                                        </React.Fragment>
                                    );
                                })}
                            </BuilderPageContainer>
                        );
                    })}
                </div>
                <CustomSignature
                    signatureField={signatureField}
                    signaturePopup={signaturePopup}
                    setSignaturePopup={setSignaturePopup}
                    addSignature={addSignature}
                    getRecentSignatures={getRecentSignatures}
                    setSelectedRecentSignValue={setSelectedRecentSignValue}
                    setAllRecentSign={setAllRecentSign}
                    allRecentSign={allRecentSign}
                />
            </div>
        </CustomDropComponent>
    );
}

export default memo(EnvelopeFormViewInner);

const useWindowWide = (size) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);

    return width > size;
};

