import React, { memo } from "react";
import AddTextField from "../FormFields/AddTextField";
import AddCheckBox from "../FormFields/AddCheckBox";
import AddRadioButton from "../FormFields/AddRadioButton";
import AddSignatureField from "../FormFields/AddSignatureField";
import AddDate from "../FormFields/AddDate";
import AddDropDown from "../FormFields/AddDropDown";
import AddNumberField from "../FormFields/AddNumberField";
import AddTimeField from "../FormFields/AddTimeField";
import AddParagraph from "../FormFields/AddParagraph";
import AddName from "../FormFields/AddName";
import AddEmail from "../FormFields/AddEmail";
import AddCompany from "../FormFields/AddCompany";
import AddCheckAllBoxes from "../FormFields/AddCheckAllBoxes";

function TemplateBuilderLeftSide({ currentPdfObject, isSignatureDeleteEnable }) {
    return (
        <div className="template-builder-card-side">
            <h4>Standard Fields</h4>
            <ul>
                {!isSignatureDeleteEnable && (
                    <>
                        <li>
                            <AddTextField currentPdfObject={currentPdfObject} />
                        </li>

                        <li>
                            <AddNumberField currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddDate currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddTimeField currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddDropDown currentPdfObject={currentPdfObject} />
                        </li>

                        <li>
                            <AddCheckBox currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddRadioButton currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddParagraph currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddName currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddEmail currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddCompany currentPdfObject={currentPdfObject} />
                        </li>
                        <li>
                            <AddCheckAllBoxes currentPdfObject={currentPdfObject} />
                        </li>
                    </>
                )}

                <li>
                    <AddSignatureField
                        currentPdfObject={currentPdfObject}
                        isSignatureDeleteEnable={isSignatureDeleteEnable}
                    />
                </li>
            </ul>
        </div>
    );
}

export default memo(TemplateBuilderLeftSide);
