import React, { memo, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import useWindowSize from "hooks/useWindowSize";
import CustomResizeable from "./CustomResizeable";

function DraggleWithResize({
    singleField,
    trackPositions,
    selectFieldHandler,
    onResizeStopHandle,
    onResizeStart,
    children,
    className = "",
    ...props
}) {
    const [screenWidth] = useWindowSize();
    const nodeRef = useRef(null);

    const handleKeyDown = (e) => {
        if (document.activeElement !== nodeRef.current) return;

        const step = e.shiftKey ? 10 : 1;
        let newX = singleField.x;
        let newY = singleField.y;

        switch (e.key) {
            case 'ArrowUp':
                newY = Math.max(0, newY - step);
                break;
            case 'ArrowDown':
                newY = Math.min(792 - singleField.height, newY + step);
                break;
            case 'ArrowLeft':
                newX = Math.max(0, newX - step);
                break;
            case 'ArrowRight':
                newX = Math.min(612 - singleField.width, newX + step);
                break;
            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();

        trackPositions({ 
            x: newX,
            y: newY,
            type: 'keyboard'
        }, singleField);
    };

    useEffect(() => {
        const element = nodeRef.current;
        if (element) {
            element.tabIndex = 0;
            element.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (element) {
                element.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [singleField.x, singleField.y, singleField.width, singleField.height]);

    return (
        <Draggable
            nodeRef={nodeRef}
            position={{ x: singleField.x, y: singleField.y }}
            onDrag={(e, data) => {
                e.preventDefault();
                if (!singleField.disableDraggable) {
                    trackPositions(data, singleField);
                }
            }}
            onStop={(e, data) => {
                e.preventDefault();
                if (!singleField.disableDraggable) {
                    trackPositions(data, singleField);
                }
            }}
            scale={screenWidth <= 1440 ? 1 : 1.8}
            bounds="parent"
            disabled={singleField.disableDraggable}
            {...props}
        >
            <div 
                ref={nodeRef}
                className={`box-draggable ${className}`}
                onClick={(e) => {
                    e.preventDefault();
                    nodeRef.current?.focus();
                    selectFieldHandler(e, singleField);
                }}
                style={{ outline: 'none' }}
            >
                <CustomResizeable 
                    {...props}
                    singleField={singleField}
                    onResizeStopHandle={onResizeStopHandle}
                    onResizeStart={onResizeStart}
                >
                    {children}
                </CustomResizeable>
            </div>
        </Draggable>
    );
}

export default memo(DraggleWithResize);

