import React, { memo, useEffect, useCallback } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, message } from "antd";
import { getEmptyImage } from 'react-dnd-html5-backend';

import { saveField, setCopiedField } from "redux/fieldSlice";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";

const CustomDragLayer = ({ isDragging, currentOffset }) => {
    if (!isDragging || !currentOffset) return null;

    return (
        <div 
            style={{ 
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
                backgroundColor: '#fff',
                padding: '4px 8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'grabbing',
                userSelect: 'none'
            }}
        >
            Radio Button
        </div>
    );
};

function AddRadioButton({ currentPdfObject }) {
    const dispatch = useDispatch();
    const copiedField = useSelector((state) => state.fields.copiedField);

    const generateRadioBtnField = useCallback((position = { x: 0, y: 0 }) => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.radiobtn,
            placeholder: "",
            pageNumber: currentPdfObject.pageNumber,
            groupId: `${FIELD_TYPES.radiobtn} ${groupId}`,
            mainRadio: true,
            childrens: [unique_id],
            width: FIELD_STATIC_WIDTH.RADIO_BUTTON.width,
            height: FIELD_STATIC_WIDTH.RADIO_BUTTON.height,
            queryName: `${FIELD_TYPES.radiobtn}-${unique_id}`,
            x: position.x,
            y: position.y
        };
    }, [currentPdfObject.pageNumber]);

    const [{ isDragging, currentOffset }, drag, preview] = useDrag(() => ({
        type: fieldDraggleType,
        item: generateRadioBtnField,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset()
        }),
        options: { dropEffect: 'move' }
    }));

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const handleCopy = () => {
        const newField = generateRadioBtnField();
        dispatch(setCopiedField(newField));
        message.success('Radio button field copied to clipboard');
    };

    const menuItems = [
        {
            key: 'copy',
            label: 'Copy Field',
            onClick: handleCopy
        }
    ];

    return (
        <>
            <Dropdown menu={{ items: menuItems }} trigger={['contextMenu']}>
                <Button
                    ref={drag}
                    onClick={() => dispatch(saveField(generateRadioBtnField()))}
                    className="common-button"
                    icon={<i className="paso-radiobutton" />}
                    style={{
                        cursor: isDragging ? 'grabbing' : 'grab',
                        opacity: isDragging ? 0.5 : 1,
                        userSelect: 'none'
                    }}
                >
                    Radio Button
                </Button>
            </Dropdown>
            <CustomDragLayer isDragging={isDragging} currentOffset={currentOffset} />
        </>
    );
}

export default memo(AddRadioButton);