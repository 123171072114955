import React, { memo, useEffect, useCallback } from "react";
import { Button, Dropdown, message } from "antd";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyImage } from 'react-dnd-html5-backend';
import { saveField, setCopiedField } from "redux/fieldSlice";
import { UtilService } from "util/util.service";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";

const CustomDragLayer = ({ isDragging, currentOffset }) => {
    if (!isDragging || !currentOffset) return null;
    
    return (
        <div
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
                backgroundColor: '#fff',
                padding: '4px 8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'grabbing',
                userSelect: 'none'
            }}
        >
            Check Box
        </div>
    );
};

function AddCheckBox({ currentPdfObject }) {
    const dispatch = useDispatch();
    const copiedField = useSelector((state) => state.fields.copiedField);

    const generateCheckboxFields = useCallback(() => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.checkbox,
            placeholder: "",
            pageNumber: currentPdfObject.pageNumber,
            groupId: `${FIELD_TYPES.checkbox} ${groupId}`,
            mainCheckbox: true,
            childrens: [unique_id],
            width: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.width,
            height: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.height,
            queryName: `${FIELD_TYPES.checkbox}-${unique_id}`,
            x: 0,
            y: 0
        };
    }, [currentPdfObject.pageNumber]);

    const [{ isDragging, currentOffset }, dragRef, preview] = useDrag({
        type: fieldDraggleType,
        item: generateCheckboxFields,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset()
        }),
        options: {
            dropEffect: 'move'
        }
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const handleCopy = () => {
        const newField = generateCheckboxFields();
        dispatch(setCopiedField(newField));
        message.success('Checkbox field copied to clipboard');
    };

    const menuItems = [
        {
            key: 'copy',
            label: 'Copy Field',
            onClick: handleCopy
        }
    ];

    const onFieldFinish = () => {
        const checkboxObj = generateCheckboxFields();
        dispatch(saveField(checkboxObj));
    };

    return (
        <>
            <Dropdown menu={{ items: menuItems }} trigger={['contextMenu']}>
                <Button
                    ref={dragRef}
                    onClick={onFieldFinish}
                    className="common-button"
                    icon={<i className="paso-checkbox"></i>}
                    style={{ 
                        cursor: isDragging ? 'grabbing' : 'grab',
                        opacity: isDragging ? 0.5 : 1,
                        userSelect: 'none'
                    }}
                >
                    Check Box
                </Button>
            </Dropdown>
            <CustomDragLayer isDragging={isDragging} currentOffset={currentOffset} />
        </>
    );
}

export default memo(AddCheckBox);