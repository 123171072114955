import React, { useState, useCallback, memo, Children, cloneElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TemplateBuilderRightSide from "./RightSide/TemplateBuilderRightSide";
import TemplateBuilderLeftSide from "./LeftSide/TemplateBuilderLeftSide";
import DropTarget from "../../../src/Components/TemplateBuilderWrapper/DropTraget/DropTarget";
import { defaultFieldObject } from "./FormFields/constant";
import { saveSingleFieldId, updateAllFields, updateField } from "redux/fieldSlice";
import { useQueryGetTemplateById } from "queries/template.queries";
import { FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";
import "./TemplateBuilderWrapper.less";

function TemplateBuilderWrapper({ children, isSignatureDeleteEnable = false, className = "" }) {
    const fields = useSelector((state) => state.fields.fields);
    const [loading, setLoading] = useState(false);
    const [currentPdfObject, setCurrentPdfObject] = useState({
        pageNumber: 1,
        imageUrl: ""
    });

    const { template_id } = useParams();
    const dispatch = useDispatch();

    const {
        reviewer,
        isFormEditible = false,
        showButtons = 0
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);

    const review = reviewer && JSON.parse(reviewer);
    const isUserAllowedToEditForm = isFormEditible && JSON.parse(isFormEditible);

    const { data: singleTemplate, isFetching: getTemplateByIdLoading } = useQueryGetTemplateById(
        template_id,
        (data) => {
            if (getTemplateByIdLoading) {
                const firstTemplateImage = data?.attributes?.templateImages?.find(() => true)?.images?.path;
                setCurrentPdfObject({
                    ...currentPdfObject,
                    imageUrl: firstTemplateImage
                });

                const tempTabData = data?.attributes?.templateDetail?.tabs;
                if (tempTabData?.length > 0) {
                    const tabData = tempTabData.map((singleField) => ({
                        ...defaultFieldObject,
                        ...singleField
                    }));
                    dispatch(updateAllFields(tabData));
                }
            }
        },
        isSignatureDeleteEnable
    );

    const trackPositions = useCallback((item, position) => {
        dispatch(
            updateField({
                id: item.id,
                x: Math.floor(position.x),
                y: Math.floor(position.y)
            })
        );
    }, [dispatch]);

    const selectFieldHandler = useCallback((event, singleFieldParam) => {
        event.preventDefault();

        if (singleFieldParam.type === FIELD_TYPES.checkbox && singleFieldParam.mainCheckbox === false) {
            const cloneFields = UtilService.deepClone(fields);
            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainCheckboxObject = filteredGroupId.find(
                    (singleFieldCheckbox) => singleFieldCheckbox.mainCheckbox
                );
                dispatch(saveSingleFieldId(findMainCheckboxObject.id));
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else if (singleFieldParam.type === FIELD_TYPES.radiobtn) {
            const cloneFields = UtilService.deepClone(fields);
            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainRadioObject = filteredGroupId.find(
                    (singleFieldRadio) => singleFieldRadio.mainRadio
                );
                dispatch(saveSingleFieldId(findMainRadioObject.id));
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else {
            dispatch(saveSingleFieldId(singleFieldParam.id));
        }
    }, [dispatch, fields]);

    const templateBuilderLeftSide = { currentPdfObject };

    const middleTemplateSideProps = {
        singleTemplate,
        currentPdfObject,
        setCurrentPdfObject,
        trackPositions,
        selectFieldHandler
    };

    const rightTemplateProps = {
        loading,
        setLoading,
        template_id,
        currentPdfObject
    };

    const colProps = parseInt(showButtons) === 1 ? { span: 24 } : { sm: 24, md: 17, lg: 17 };

    return (
        <div>
            <div className={`template-builder-view-container-new ${className}`}>
                <DndProvider backend={HTML5Backend}>
                    <Row gutter={30}>
                        {parseInt(showButtons) !== 1 && (
                            <Col sm={24} md={3} lg={3} className="left-side-col">
                                {!isSignatureDeleteEnable || !!review || isUserAllowedToEditForm ? (
                                    <TemplateBuilderLeftSide
                                        {...templateBuilderLeftSide}
                                        isSignatureDeleteEnable={isSignatureDeleteEnable}
                                    />
                                ) : null}
                            </Col>
                        )}
                        <Col {...colProps} className="pdf-middle-col">
                            <DropTarget onDrop={trackPositions}>
                                {Children.map(children, (child) =>
                                    cloneElement(child, {
                                        ...middleTemplateSideProps
                                    })
                                )}
                            </DropTarget>
                        </Col>
                        {parseInt(showButtons) !== 1 && (
                            <Col sm={24} md={4} lg={4} className="right-side-col">
                                {!isSignatureDeleteEnable ? (
                                    <TemplateBuilderRightSide {...rightTemplateProps} />
                                ) : null}
                            </Col>
                        )}
                    </Row>
                </DndProvider>
            </div>
        </div>
    );
}

export default memo(TemplateBuilderWrapper);