import React, { memo, useEffect, useCallback } from "react";
import { Button, Dropdown, message } from "antd";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyImage } from 'react-dnd-html5-backend';
import { saveField, setCopiedField } from "redux/fieldSlice";
import { UtilService } from "util/util.service";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";

const CustomDragLayer = ({ isDragging, currentOffset }) => {
    if (!isDragging || !currentOffset) return null;
    
    return (
        <div
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
                backgroundColor: '#fff',
                padding: '4px 8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'grabbing',
                userSelect: 'none'
            }}
        >
            Check All Boxes
        </div>
    );
};

function AddCheckAllBoxes({ currentPdfObject }) {
    const dispatch = useDispatch();
    const copiedField = useSelector((state) => state.fields.copiedField);
    const fields = useSelector((state) => state.fields.fields);

    const generateCheckAllBoxesField = useCallback(() => {
        const unique_id = UtilService.generateUniqueId();
        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.checkAllBoxes,
            placeholder: "",
            pageNumber: currentPdfObject.pageNumber,
            width: 20, // Fixed width for checkbox
            height: 20, // Fixed height for checkbox
            queryName: `${FIELD_TYPES.checkAllBoxes}-${unique_id}`,
            x: 0,
            y: 0,
            value: false,
            checked: false,
            isResizable: false, // Prevent resizing
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        };
    }, [currentPdfObject.pageNumber]);

    const [{ isDragging, currentOffset }, dragRef, preview] = useDrag({
        type: fieldDraggleType,
        item: generateCheckAllBoxesField,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset()
        })
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const handleCopy = () => {
        // Check if a Check All Boxes field already exists
        const checkAllExists = fields.some(field => field.type === FIELD_TYPES.checkAllBoxes);
        
        if (checkAllExists) {
            message.warning('Check All Boxes field already exists');
            return;
        }

        const newField = generateCheckAllBoxesField();
        dispatch(setCopiedField({ ...newField, isCheckAllBoxes: true }));
        message.success('Check All Boxes field copied to clipboard');
    };

    const menuItems = [
        {
            key: 'copy',
            label: 'Copy Field',
            onClick: handleCopy
        }
    ];

    const onFieldFinish = () => {
        // Check if a Check All Boxes field already exists
        const checkAllExists = fields.some(field => field.type === FIELD_TYPES.checkAllBoxes);
        
        if (checkAllExists) {
            message.warning('Only one Check All Boxes field is allowed');
            return;
        }

        const checkAllBoxesObj = generateCheckAllBoxesField();
        dispatch(saveField(checkAllBoxesObj));
        message.success('Check All Boxes field added');
    };

    return (
        <>
            <Dropdown menu={{ items: menuItems }} trigger={['contextMenu']}>
                <Button
                    ref={dragRef}
                    onClick={onFieldFinish}
                    className="common-button"
                    icon={<i className="paso-checkbox"></i>}
                    style={{ 
                        cursor: isDragging ? 'grabbing' : 'grab',
                        opacity: isDragging ? 0.5 : 1,
                        userSelect: 'none'
                    }}
                >
                    Check All Boxes
                </Button>
            </Dropdown>
            <CustomDragLayer isDragging={isDragging} currentOffset={currentOffset} />
        </>
    );
}

export default memo(AddCheckAllBoxes);
