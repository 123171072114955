import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";
import { defaultFieldObject } from "Components/TemplateBuilderWrapper/FormFields/constant";
import { selectTabData } from "./tabSlice";

export default function useField(checkboxRef) {  // Add checkboxRef as parameter
    const tabData = useSelector(selectTabData);
    const [state, setState] = useState({
        fields: [],
        singleFieldId: 0,
        scrollY: 0
    });

    const updateQueryParamsToFields = (payload, fields) => {
        let fieldCloneForUpdate = UtilService.deepClone(fields);

        //if payrate is exist we will use services

        let queryFields = UtilService.deepClone(payload);

        //custom services work
        const SERVICES = {
            CFC: "CFC",
            ISS: "ISS",
            HH: "HH",
            REH: "REH"
        };

        const queryNamesOfServices = {
            cfcRate: "cfcRate",
            issRate: "issRate",
            hhRate: "hhRate",
            rehRate: "rehRate",
            firstNameLastName: "firstNameLastName",
            lastNameFirstName: "lastNameFirstName"
        };

        const services = "services";

        const findServiceKey = queryFields?.find((singleQueryField) => singleQueryField.queryName === services);

        let findCfcRate;
        let findIssRate;
        let findHhRate;
        let findRehRate;

        if (findServiceKey) {
            const serviceValues = findServiceKey.value;
            findCfcRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.CFC)?.rate;
            findIssRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.ISS)?.rate;
            findHhRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.HH)?.rate;
            findRehRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.REH)?.rate;
        }

        //custom services work

        fieldCloneForUpdate.forEach(({ queryName, value }, index) => {
            //custom services work
            if (queryName === queryNamesOfServices.cfcRate) {
                fieldCloneForUpdate[index].value = findCfcRate;
            }

            if (queryName === queryNamesOfServices.hhRate) {
                fieldCloneForUpdate[index].value = findHhRate;
            }

            if (queryName === queryNamesOfServices.issRate) {
                fieldCloneForUpdate[index].value = findIssRate;
            }

            if (queryName === queryNamesOfServices.rehRate) {
                fieldCloneForUpdate[index].value = findRehRate;
            }

            //custom services work
            const obj = queryFields?.find((item) => item?.queryName === queryName);
            if (!!obj) {
                if (
                    fieldCloneForUpdate[index].type !==
                    (FIELD_TYPES.signature || FIELD_TYPES.checkbox || FIELD_TYPES.radiobtn)
                ) {
                    fieldCloneForUpdate[index].value = obj?.value;
                }
            }
        });
        setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
    };
    const resetFields = () => {
        setState((prev) => ({ ...prev, fields: [] }));
    };
    const saveField = ({ payload }) => {
        if (payload) {
            let fieldClone = UtilService.deepClone(state.fields);
            state.fields = [...fieldClone, payload];
        }
    };
    const deleteField = ({ payload }) => {
        let fieldClone = UtilService.deepClone(state.fields);
        const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
        setState((prev) => ({ ...prev, fields: filteredFields }));
    };
    const deleteFieldSigned = ({ payload }) => {
        //delete signed field from fields array
        let fieldCloneForUpdate = UtilService.deepClone(state.fields);
        const filteredFields = fieldCloneForUpdate.filter((singleField) => singleField.id !== payload.id);
        fieldCloneForUpdate = filteredFields;

        //delete children field from original signature
        const originalSignatureFieldId = state.singleFieldId;

        const findOriginalFieldIndex = fieldCloneForUpdate.findIndex(
            (singleField) => singleField.id === originalSignatureFieldId
        );

        const filteredOriginalFieldChildrens = fieldCloneForUpdate[findOriginalFieldIndex].childrens.filter(
            (singleChildren) => singleChildren.id !== payload.id
        );

        fieldCloneForUpdate[findOriginalFieldIndex].childrens = filteredOriginalFieldChildrens;
        setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
    };
    const updateField = (payload) => {
        const fieldCloneForUpdate = UtilService.deepClone(state.fields);

        console.log("update filed", fieldCloneForUpdate);
        const findIndex = fieldCloneForUpdate.findIndex((singleField) => singleField?.id == payload?.id);

        if (findIndex !== -1) {
            fieldCloneForUpdate[findIndex] = {
                ...fieldCloneForUpdate[findIndex],
                ...payload
            };

            setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
        }
    };

    const updateSignatureFormValue = (payload) => {
        const fieldCloneForUpdate = UtilService.deepClone(state.fields);
        const findIndex = fieldCloneForUpdate.findIndex((singleField) => singleField.id === payload.id);

        if (findIndex !== -1) {
            const findSignatureField = fieldCloneForUpdate[findIndex];
            fieldCloneForUpdate[findIndex] = {
                ...findSignatureField,
                ...payload
            };

            if (findSignatureField.childrens?.length > 0) {
                findSignatureField.childrens.forEach((singleChildren) => {
                    const findIndexChildren = fieldCloneForUpdate.findIndex(
                        (singleField) => singleField.id === singleChildren.id
                    );

                    const { currentDateTime } = payload;

                    if (findIndexChildren !== -1) {
                        fieldCloneForUpdate[findIndexChildren].value = currentDateTime;
                    }
                });
            }
        }
        setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
    };
    const updateAllFields = (payload) => {
        setState((prev) => ({ ...prev, fields: payload }));
    };
    const saveSingleFieldId = (payload) => {
        setState((prev) => ({ ...prev, singleFieldId: payload }));
    };
    const addField = (payload) => {
        setState((prev) => {
            const idExists = prev.fields.some((field) => field.id === payload.id);

            if (!idExists) {
                return {
                    ...prev,
                    fields: [...prev.fields, payload]
                };
            }

            return prev;
        });
    };

    //checkbox work
    const deleteCheckboxOption = ({ payload }) => {
        const fieldClone = UtilService.deepClone(state.fields);

        const findMainCheckboxIndex = UtilService.findMainCheckboxIndex(payload.groupId, fieldClone);
        const filteredCheckbox = fieldClone[findMainCheckboxIndex].childrens.filter(
            (singleCheckBoxFilter) => singleCheckBoxFilter !== payload.id
        );
        fieldClone[findMainCheckboxIndex].childrens = filteredCheckbox;

        //delete checkbox and also delete it from its childrens array
        const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
        setState((prev) => ({ ...prev, fields: filteredFields }));
    };
    const addCheckboxOption = ({ payload }) => {
        const singleField = payload;
        const { groupId } = singleField;
        let fieldClone = UtilService.deepClone(state.fields);
        const findMainCheckbox = UtilService.findMainCheckboxElement(groupId, fieldClone);

        const unique_id = UtilService.generateUniqueId();
        const multiplyValueOfyAxis = 5 * findMainCheckbox.childrens.length;
        const multiplyValueOfxAxis = 5 * findMainCheckbox.childrens.length;

        const fieldsData = {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.checkbox,
            pageNumber: findMainCheckbox.pageNumber,
            groupId: groupId,
            x: findMainCheckbox.x + multiplyValueOfxAxis,
            y: findMainCheckbox.y + multiplyValueOfyAxis,
            value: false,
            width: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.width,
            height: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.height
        };

        //save fields
        fieldClone = [...fieldClone, fieldsData];

        //update field
        const fieldCloneForUpdate = [...fieldClone];
        const findIndex = fieldCloneForUpdate.findIndex((singleFieldClone) => singleFieldClone.id === singleField.id);

        if (findIndex !== -1) {
            const findObject = fieldCloneForUpdate[findIndex];
            fieldCloneForUpdate[findIndex] = {
                ...fieldCloneForUpdate[findIndex],
                childrens: [...findObject.childrens, unique_id]
            };
            setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
        }
    };

    const addSignatureDateSigned = ({ payload }) => {
        const { id: fieldIdParam } = payload;
        let fieldClone = UtilService.deepClone(state.fields);

        const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === fieldIdParam);

        const unique_id = UtilService.generateUniqueId();

        //add new array in fields
        const mainSignature = fieldClone[findIndex];
        const oldSignedChildrens = mainSignature.childrens;

        const calculateYAxisSpace = oldSignedChildrens.length === 0 ? mainSignature.height + 10 : mainSignature.height;

        const fieldsData = {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.signature_signed_date,
            pageNumber: fieldClone[findIndex].pageNumber,
            parentId: fieldClone[findIndex].id,
            groupId: mainSignature.groupId,
            x: mainSignature.x,
            y: mainSignature.y + calculateYAxisSpace * (oldSignedChildrens.length + 1),
            value: ""
        };

        fieldClone = [...fieldClone, fieldsData];

        //connect with date field

        const childrenDropdown = {
            id: unique_id
        };

        if (findIndex !== -1) {
            fieldClone[findIndex].childrens = [...fieldClone[findIndex].childrens, childrenDropdown];
        }
        setState((prev) => ({ ...prev, fields: fieldClone }));
    };
    //radio button work
    const deleteRadioButtonOption = ({ payload }) => {
        const fieldClone = UtilService.deepClone(state.fields);
        const findMainRadioIndex = UtilService.findMainRadioIndex(payload.groupId, fieldClone);
        const filteredRadio = fieldClone[findMainRadioIndex].childrens.filter(
            (singleRadioFilter) => singleRadioFilter !== payload.id
        );
        fieldClone[findMainRadioIndex].childrens = filteredRadio;

        //delete radio and also delete it from its childrens array
        const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
        setState((prev) => ({ ...prev, fields: filteredFields }));
    };
    const addRadioButtonOption = ({ payload }) => {
        const singleField = payload;
        const { groupId } = singleField;
        let fieldClone = UtilService.deepClone(state.fields);
        const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);

        const unique_id = UtilService.generateUniqueId();
        const multiplyValueOfyAxis = 5 * findMainRadio.childrens.length;
        const multiplyValueOfxAxis = findMainRadio.x + 5 * findMainRadio.childrens.length;

        const fieldsData = {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.radiobtn,
            pageNumber: findMainRadio.pageNumber,
            groupId: groupId,
            x: multiplyValueOfxAxis,
            y: findMainRadio.y + multiplyValueOfyAxis,
            value: false,
            width: FIELD_STATIC_WIDTH.RADIO_BUTTON.width,
            height: FIELD_STATIC_WIDTH.RADIO_BUTTON.height
        };

        //save fields
        fieldClone = [...fieldClone, fieldsData];

        //update field
        const fieldCloneForUpdate = [...fieldClone];
        const findIndex = fieldCloneForUpdate.findIndex((singleFieldClone) => singleFieldClone.id === singleField.id);

        if (findIndex !== -1) {
            const findObject = fieldCloneForUpdate[findIndex];
            fieldCloneForUpdate[findIndex] = {
                ...fieldCloneForUpdate[findIndex],
                childrens: [...findObject.childrens, unique_id]
            };

            setState((prev) => ({ ...prev, fields: fieldCloneForUpdate }));
        }
    };
    const radioOptionOnChangeChecked = ({ payload }) => {
        const { checked, singleRepeaterField } = payload;

        const { groupId } = singleRepeaterField;
        const fieldClone = UtilService.deepClone(state.fields);

        const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);
        const mainRadioChildrens = findMainRadio.childrens || [];

        if (mainRadioChildrens.length > 0) {
            mainRadioChildrens.forEach((singleField) => {
                const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleField);
                if (findIndex !== -1) {
                    fieldClone[findIndex].value = false;
                    fieldClone[findIndex].checked = false;
                }
            });
        }

        const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleRepeaterField.id);

        if (findIndex !== -1) {
            fieldClone[findIndex].value = checked;
            fieldClone[findIndex].checked = checked;
            setState((prev) => ({ ...prev, fields: fieldClone }));
        }
    };
    const radioOptionOnChangeCheckedEnvelopeView = (payload) => {
        const { singleRepeaterField, value } = payload;

        const { groupId } = singleRepeaterField;

        const fieldClone = UtilService.deepClone(state.fields);

        const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);
        const mainRadioChildrens = findMainRadio.childrens || [];

        if (mainRadioChildrens.length > 0) {
            mainRadioChildrens.forEach((singleField) => {
                const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleField);
                if (findIndex !== -1) {
                    fieldClone[findIndex].checked = false;
                    fieldClone[findIndex].value = false;
                }
            });
        }

        const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleRepeaterField.id);

        if (findIndex !== -1) {
            fieldClone[findIndex].value = value;
            fieldClone[findIndex].checked = true;
            fieldClone[findIndex].validationStatus = "";
        }
        setState((prev) => ({ ...prev, fields: fieldClone }));
    };
    const addDropdownOption = ({ payload }) => {
        const { id: fieldIdParam } = payload;

        const findIndex = state.fields.findIndex((singleFieldRedux) => singleFieldRedux.id === fieldIdParam);

        const unique_id = UtilService.generateUniqueId();

        const childrenDropdown = {
            id: unique_id,
            option: "",
            value: ""
        };

        if (findIndex !== -1) {
            setState((prev) => {
                const updatedFields = [...prev.fields];
                updatedFields[findIndex] = {
                    ...updatedFields[findIndex],
                    childrens: [...updatedFields[findIndex].childrens, childrenDropdown]
                };
                return { ...prev, fields: updatedFields };
            });
            // state.fields[findIndex].childrens = [...state.fields[findIndex].childrens, childrenDropdown];
        }
    };
    const removeDropDownOption = ({ payload }) => {
        const { parentFieldId, childrenFieldId } = payload;
        const findIndex = state.fields.findIndex((singleFieldRedux) => singleFieldRedux.id === parentFieldId);

        if (findIndex !== -1) {
            const childrensOfDropdown = [...state.fields[findIndex].childrens];

            const filteredDropdownChildrens = childrensOfDropdown.filter(
                (singleChildrenDropdown) => singleChildrenDropdown.id !== childrenFieldId
            );
            setState((prev) => {
                const updatedFields = [...prev.fields];
                updatedFields[findIndex] = {
                    ...updatedFields[findIndex],
                    childrens: [filteredDropdownChildrens]
                };
                return { ...prev, fields: updatedFields };
            });
            // state.fields[findIndex].childrens = filteredDropdownChildrens;
        }
    };
    const updateDropDownOption = ({ payload }) => {
        const { parentFieldId, childrenFieldId, updateValues } = payload;

        const fieldClone = UtilService.deepClone(state.fields);
        const parentFieldfindIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === parentFieldId);

        if (parentFieldfindIndex !== -1) {
            const childrensOfDropdown = fieldClone[parentFieldfindIndex].childrens;

            const findChildrenIndex = childrensOfDropdown.findIndex(
                (singleChildrenDropdown) => singleChildrenDropdown.id === childrenFieldId
            );

            if (findChildrenIndex !== -1) {
                childrensOfDropdown[findChildrenIndex] = {
                    ...childrensOfDropdown[findChildrenIndex],
                    ...updateValues
                };
            }
            setState((prev) => ({ ...prev, fields: fieldClone }));
        }
    };
    const scrollPosition = ({ payload }) => {
        state.scrollY = payload;
    };

    const handleCheckAllBoxes = (checked) => {
        setState(prev => {
            const updatedFields = prev.fields.map(field => {
                if (field.type === FIELD_TYPES.checkbox) {
                    return {
                        ...field,
                        value: checked,
                        checked: checked
                    };
                }
                if (field.type === FIELD_TYPES.checkAllBoxes) {
                    return {
                        ...field,
                        value: checked,
                        checked: checked
                    };
                }
                return field;
            });

            return {
                ...prev,
                fields: updatedFields
            };
        });
    };

    return {
        fields: state.fields,
        radioOptionOnChangeCheckedEnvelopeView,
        updateDropDownOption,
        addDropdownOption,
        radioOptionOnChangeChecked,
        saveSingleFieldId,
        saveField,
        deleteField,
        updateField,
        updateAllFields,
        addCheckboxOption,
        deleteCheckboxOption,
        deleteRadioButtonOption,
        addRadioButtonOption,
        removeDropDownOption,
        resetFields,
        scrollPosition,
        addSignatureDateSigned,
        deleteFieldSigned,
        updateQueryParamsToFields,
        updateSignatureFormValue,
        addField,
        handleCheckAllBoxes
    };
}

