import React, { useRef, useEffect, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import { fieldDraggleType } from '../FormFields/constant';
import { pasteField } from 'redux/fieldSlice';
import { FIELD_TYPES } from 'util/util.constant';

const DropTarget = ({ onDrop, children }) => {
    const dropRef = useRef(null);
    const dispatch = useDispatch();
    const copiedField = useSelector((state) => state.fields.copiedField);
    const fields = useSelector((state) => state.fields.fields);
    const menuRef = useRef(null);

    const removeContextMenu = () => {
        if (menuRef.current && document.body.contains(menuRef.current)) {
            document.body.removeChild(menuRef.current);
            menuRef.current = null;
        }
    };

    const isWithinFormBoundaries = useCallback((event) => {
        const formElement = document.getElementById('template-builder-middle-side');
        if (!formElement) return false;

        const formRect = formElement.getBoundingClientRect();
        return (
            event.clientX >= formRect.left &&
            event.clientX <= formRect.right &&
            event.clientY >= formRect.top &&
            event.clientY <= formRect.bottom
        );
    }, []);

    const getRelativePosition = useCallback((event) => {
        const formElement = document.getElementById('template-builder-middle-side');
        if (!formElement) return { x: 0, y: 0 };

        const formRect = formElement.getBoundingClientRect();
        return {
            x: event.clientX - formRect.left,
            y: event.clientY - formRect.top
        };
    }, []);

    const handleContextMenu = useCallback((e) => {
        e.preventDefault();
        if (!copiedField) return;

        // Check if we're inside the form
        if (isWithinFormBoundaries(e)) {
            const position = getRelativePosition(e);
            
            const menu = document.createElement('div');
            menuRef.current = menu;
            menu.className = 'context-menu';
            menu.style.cssText = `
                position: fixed;
                left: ${e.clientX}px;
                top: ${e.clientY}px;
                background: white;
                padding: 8px 12px;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.15);
                cursor: pointer;
                z-index: 1000;
                animation: fadeIn 0.1s ease;
            `;
            menu.innerHTML = 'Paste Field';
            document.body.appendChild(menu);

            menu.onclick = () => {
                dispatch(pasteField({ position }));
                removeContextMenu();
            };

            const handleClickOutside = (event) => {
                if (menuRef.current && !menuRef.current.contains(event.target)) {
                    removeContextMenu();
                    document.removeEventListener('click', handleClickOutside);
                }
            };

            setTimeout(() => {
                document.addEventListener('click', handleClickOutside);
            }, 0);
        }
    }, [copiedField, dispatch, fields, isWithinFormBoundaries, getRelativePosition]);

    const [{ isOver }, drop] = useDrop({
        accept: fieldDraggleType,
        drop: (item, monitor) => {
            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) return;

            // Create a synthetic event object for boundary check
            const syntheticEvent = {
                clientX: clientOffset.x,
                clientY: clientOffset.y
            };

            if (isWithinFormBoundaries(syntheticEvent)) {
                const position = getRelativePosition(syntheticEvent);
                onDrop(item, position);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    useEffect(() => {
        const dropElement = dropRef.current;
        if (dropElement) {
            dropElement.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (dropElement) {
                dropElement.removeEventListener('contextmenu', handleContextMenu);
            }
            removeContextMenu();
        };
    }, [handleContextMenu]);

    return (
        <div
            ref={(node) => {
                dropRef.current = node;
                drop(node);
            }}
            style={{
                position: 'relative',
                minHeight: '100%',
                width: '100%',
                background: isOver ? 'rgba(0,0,0,0.02)' : 'transparent',
                transition: 'all 0.2s ease',
                overflow: 'hidden' // Add this to prevent elements from being placed outside
            }}
        >
            {children}
        </div>
    );
};

export default DropTarget;