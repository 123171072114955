import React, { memo, useEffect, useCallback } from "react";
import { Button, Dropdown, message } from "antd";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyImage } from 'react-dnd-html5-backend';
import { saveField, setCopiedField } from "redux/fieldSlice";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { UtilService } from "util/util.service";

const CustomDragLayer = ({ isDragging, currentOffset }) => {
  if (!isDragging || !currentOffset) return null;
  
  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        backgroundColor: '#fff',
        padding: '4px 8px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        cursor: 'grabbing',
        userSelect: 'none'
      }}
    >
      Dropdown Field
    </div>
  );
};

function AddDropDown({ currentPdfObject }) {
    const dispatch = useDispatch();
    const copiedField = useSelector((state) => state.fields.copiedField);

    const generateDropdownField = useCallback((position = { x: 0, y: 0 }) => ({
        ...defaultFieldObject,
        id: UtilService.generateUniqueId(),
        type: FIELD_TYPES.dropdown,
        placeholder: FIELD_TYPES.dropdown,
        pageNumber: currentPdfObject.pageNumber,
        width: FIELD_STATIC_WIDTH.DROPDOWN.width,
        height: FIELD_STATIC_WIDTH.DROPDOWN.height,
        queryName: `${FIELD_TYPES.dropdown}-${UtilService.generateUniqueId()}`,
        x: position.x,
        y: position.y
    }), [currentPdfObject.pageNumber]);

    const [{ isDragging, currentOffset }, drag, preview] = useDrag(() => ({
        type: fieldDraggleType,
        item: generateDropdownField,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset()
        }),
        options: {
            dropEffect: 'move'
        }
    }));

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const handleCopy = () => {
        const newField = generateDropdownField();
        dispatch(setCopiedField(newField));
        message.success('Dropdown field copied to clipboard');
    };

    const menuItems = [
        {
            key: 'copy',
            label: 'Copy Field',
            onClick: handleCopy
        }
    ];

    return (
        <>
            <Dropdown menu={{ items: menuItems }} trigger={['contextMenu']}>
                <Button
                    ref={drag}
                    onClick={() => dispatch(saveField(generateDropdownField()))}
                    className="common-button"
                    icon={<i className="paso-dropdown" />}
                    style={{ 
                        cursor: isDragging ? 'grabbing' : 'grab',
                        opacity: isDragging ? 0.5 : 1,
                        userSelect: 'none'
                    }}
                >
                    Dropdown
                </Button>
            </Dropdown>
            <CustomDragLayer isDragging={isDragging} currentOffset={currentOffset} />
        </>
    );
}

export default memo(AddDropDown);