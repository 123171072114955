import {
    maxFieldHeight,
    maxFieldWidth,
    minFieldHeight,
    minFieldWidth
} from "Components/TemplateBuilderWrapper/FormFields/constant";
import useWindowSize from "hooks/useWindowSize";
import { Resizable } from "re-resizable";
import React, { useState } from "react";
import { FIELD_TYPES } from "util/util.constant";

function CustomResizeable(props) {
    const {
        children,
        singleField,
        onResizeStopHandle,
        onResizeStart,
        onResize = () => {},
        className = "",
        isResizeEnableFromProps = true
    } = props;
    const [isResizingStart, setIsResizingStart] = useState(false);
    const [screenWidth] = useWindowSize();

    const adjustScaleForDraggableAndResize = screenWidth <= 1440 ? 1 : screenWidth <= 1880 ? 1.8 : 1.8;
    console.log("🚀 ~ CustomResizeable ~ adjustScaleForDraggableAndResize:", adjustScaleForDraggableAndResize)

    const isResizableEnable =
        singleField.type === FIELD_TYPES.checkbox || singleField.type === FIELD_TYPES.radiobtn ? false : true;

    const resizeComponentsProps = {
        maxHeight: maxFieldHeight,
        maxWidth: maxFieldWidth,
        minWidth: minFieldWidth,
        minHeight: minFieldHeight,
        size: {
            width: singleField?.width,
            height: singleField?.height
        },
        onResizeStop: (e, direction, ref, d) => {
            const newWidth = Math.round(ref.clientWidth);
            const newHeight = Math.round(ref.clientHeight);
            
            // Ensure the field stays within bounds after resize
            const boundedX = Math.max(0, Math.min(singleField.x, 612 - newWidth));
            const boundedY = Math.max(0, Math.min(singleField.y, 792 - newHeight));
            
            onResizeStopHandle(e, direction, ref, d, {
                ...singleField,
                x: boundedX,
                y: boundedY,
                width: newWidth,
                height: newHeight,
                disableDraggable: false
            });
            setIsResizingStart(false);
        },
        onResizeStart: (e, direction, ref, d) => {
            setIsResizingStart(true);
            onResizeStart(e, direction, ref, d, singleField);
        },
        onResize: (e, direction, ref, d) => {
            onResize(e, direction, ref, d, singleField);
        },
        enable: {
            top: isResizableEnable,
            right: isResizableEnable,
            bottom: isResizableEnable,
            left: isResizableEnable,
            topRight: isResizableEnable,
            bottomRight: isResizableEnable,
            bottomLeft: isResizableEnable,
            topLeft: isResizableEnable
        },
        scale: adjustScaleForDraggableAndResize
    };

    if (isResizeEnableFromProps === false) {
        resizeComponentsProps.enable = false;
    }
    return (
        <Resizable
            className={`resize-able-item-custom ${className} ${isResizingStart ? "disable-border-resize-able" : ""}`}
            {...resizeComponentsProps}
        >
            {children}
        </Resizable>
    );
}

export default CustomResizeable;
