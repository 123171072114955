import CustomOptimizeLazyImageLoad from "Components/CustomOptimizeLazyImageLoad/CustomOptimizeLazyImageLoad";
import React, { memo, useState, useLayoutEffect } from "react";

function useWindowSize() {
    const [size, setSize] = useState([0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.screen.width]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

function BuilderPageContainer({
    currentPageNo,
    handleMouseEvents = () => {},
    imageUrl,
    children,
    isSignatureDeleteEnable = false
}) {
    // console.log("imageUrl",imageUrl)
    return (
        <div style={{ textAlign: "center" }}>
            <h4>{currentPageNo}</h4>

            <div
                onMouseMove={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseOver={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseEnter={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseUp={(event) => handleMouseEvents(event, currentPageNo)}
                className="main-template-middle-container"
                style={{ 
                    position: 'relative',
                    transform: 'none', // Changed from translate(0,0)
                    zIndex: 1,
                    isolation: 'isolate' // Add this for better stacking context
                }}
            >
                <CustomOptimizeLazyImageLoad src={imageUrl} className="lazy-load-image" style={{ width: "100%" }} />
                {isSignatureDeleteEnable ? children : (
                    <div 
                        id="template-builder-middle-side" 
                        style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0,
                            overflow: 'hidden', // Add this to contain fields
                            transformOrigin: '0 0' // Add this to ensure proper positioning
                        }}
                    >
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
}

export default memo(BuilderPageContainer);

