import { Card, Checkbox } from "antd";
import React, { memo } from "react";
import Draggable from "react-draggable";
import { QUERY_KEYS_NAMES } from "util/util.constant";

function FormViewCheckbox({ singleField, trackPositions, onCheckboxChanged, reviewer }) {
    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            disabled
        >
            <div className="box-draggable">
                <Card>
                    <Checkbox
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        onChange={(event) => onCheckboxChanged(event, singleField)}
                        checked={singleField.checked || singleField.value}
                        disabled={reviewer}
                        style={{ 
                            width: singleField.width, 
                            height: singleField.height 
                        }}
                        className={`ant-select-status-${singleField.validationStatus}`}
                        value={singleField.id}
                    />
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewCheckbox);
